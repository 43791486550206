import React, { useEffect } from 'react';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs'
import posthog from 'posthog-js'
import { useDetectAdBlock } from "adblock-detect-react";
import { useState } from 'react';

const ChatRoom = ({ userName, profileURL = '', avatarURL = '' }) => {
    const adBlockDetected = useDetectAdBlock();

    const banList = [
        'l5pmdx',
        'gylzc4',
        '1no40h1',

    ]

    const [superBanned, setSuperBanned] = useState(false)

    posthog.init('phc_d2xxwMpnG2SNbADgmirzc1F3FSRrHaXI0Jodj04S5oE', { api_host: 'https://app.posthog.com' })
    useEffect(() => {

        getFingerprint().then((fingerprint) => {
            console.log(fingerprint)
            if (banList.includes(fingerprint)) {
                setSuperBanned(true)

                posthog.capture('Super Banned User Tried To Access', { property: fingerprint })
            }
        });
        if (adBlockDetected) {
            posthog.capture('AdBlock Detected', { property: 'value' })
        }
    }, [])


    useEffect(() => {
        fetch('https://geolocation-db.com/json/0daad5e0-82e7-11ee-92e0-f5d620c7dcb4')
            .then(response => response.json())
            .then(data => {
                getFingerprint().then((fingerprint) => {
                    posthog.identify(fingerprint);
                    posthog.capture('Chat Room ' + fingerprint + " " + data.IPv4, { property: fingerprint })


                }
                )
            })
    }
        , [])



    return (
        <>

            <div id="cbox-iframe-container" onClick={
                () => {

                }
            } style={
                {
                    display: adBlockDetected || superBanned ? 'none' : 'block'
                }
            }>
                <iframe class="chat-iframe" height="100%" src="https://www3.cbox.ws/box/?boxid=3536258&boxtag=w0hsUS&nme=ActualUserName&sig=Yjg3ZTQ3MjQxMjVlN2RkY2I3MDI2ODlhNDM2NjQzMzE4NmU2NmUzMTcwNTU3MTljOTk3NjhhNjEzZTljYmJkZA%3D%3D" marginheight="0" marginwidth="0" scrolling="no" allowtransparency="yes" frameborder="0"></iframe>
            </div>

            {
                adBlockDetected ? <div>
                    <h4>You Can't Join The Live Chat With an Ad Blocker On</h4>
                    <p>Disable your ad blocker and refresh this page to chat.</p>
                </div> : null
            }

            {
                superBanned ? <div style={{
                    marginTop: '45px'
                }}>
                    <h4>You Have Been Temporarily Banned From The Chat</h4>
                    <p>
                        Please fill out the appeal form if you believe this is a mistake.
                        <a style={
                            {
                                textDecoration: 'underline',
                                marginLeft: '5px'
                            }
                        } target='_blank' href="https://forms.gle/pwq11AAQRHYqytLb6"> Appeal Form</a>
                    </p>
                </div> : null
            }
        </>
    );
};

export default ChatRoom;
